import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const ButtonStyles: ComponentStyleConfig = {
  sizes: {},
  variants: {
    primary: props => ({
      bg: props.colorMode === "light" ? "primary.600" : "primary.500",
      color: "white",
      _hover: {
        bg: mode("primary.700", "primary.400")(props),
        color: "white",
      },
    }),
    secondary: props => ({
      bg: props.colorMode === "light" ? "secondary.600" : "secondary.500",
      color: "white",
      _hover: {
        bg: mode("secondary.700", "secondary.400")(props),
        color: "white",
      },
    }),
    secondaryOutline: props => ({
      borderColor: props.colorMode === "light" ? "secondary.600" : "secondary.500",
      bg: "transparent",
      border: "1px solid",
      color: "secondary.600",
      _hover: {
        transform: "scale(1.02)",
      },
    }),
  },
  defaultProps: {},
};

import { extendTheme, ThemeProviderProps } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/button";

import { mode } from "@chakra-ui/theme-tools";

export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  fonts: {
    heading: `'Roboto Mono', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  styles: {
    global: (props: ThemeProviderProps) => ({
      body: {
        bg: mode("#151422", "#151422")(props),
      },
    }),
  },
  colors: {
    header: "#24233A",
    light: "#F9F9F9",
    dark: "#1A1A40",
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
    },
    primary: "#8054FF",
    purple: {
      "50": "#ECE5FF",
      "100": "#CAB8FF",
      "200": "#A88AFF",
      "300": "#865CFF",
      "400": "#642EFF",
      "500": "#4200FF",
      "600": "#3400CC",
      "700": "#270099",
      "800": "#1A0066",
      "900": "#0D0033",
    },
    secondary: {
      "50": "#FFE5EF",
      "100": "#FFB8D2",
      "200": "#FF8AB5",
      "300": "#FF5C99",
      "400": "#FF2E7C",
      "500": "#FF005F",
      "600": "#CC004C",
      "700": "#990039",
      "800": "#660026",
      "900": "#330013",
    },
    bar: "#25233E",
  },
  components: {
    Button,
    Input: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
});

import "../src/styles/globals.css";
import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import { LazyMotion, domAnimation } from "framer-motion";

import { Analytics } from "@vercel/analytics/react";
import "@fontsource/inter/400.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/700.css";

import client from "../src/client";
import { theme as defaultTheme } from "../src/theme";
import Script from "next/script";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <ApolloProvider client={client}>
        <ChakraProvider theme={defaultTheme}>
          <LazyMotion features={domAnimation}>
            <Component {...pageProps} />
          </LazyMotion>
          <Analytics />
        </ChakraProvider>
      </ApolloProvider>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-JQ54MMG1NG" strategy="afterInteractive" defer />
      <Script id="google-analytics" strategy="afterInteractive" defer>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-JQ54MMG1NG');
        `}
      </Script>
    </>
  );
}
